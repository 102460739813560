import React, { lazy, Suspense } from 'react'

const CustomApplicationList = lazy(() => import('./CustomApplicationList'))

export const CustomApplicationListLazy = (props) => {
  return (
    <Suspense fallback={null}>
      <CustomApplicationList {...props} />
    </Suspense>
  )
}
